import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import Drawer from '../ui/Drawer';
import { Input } from './Input';
import { InputTerms } from './InputTerms';
import { SelectCountry } from './SelectCountry';
import { Select } from './Select';
import { getCategories } from '@/lib/repository/machinesRepository';
import { useTranslationFuz } from '@/lib/types/i18n';
import { getWantedRequestPriceRangeOptions } from '@/lib/repository/leadsRepository';
import { useRouter } from 'next-translate-routes-multi-domain';
import { useQuery } from '@tanstack/react-query';
import { currencyFormat } from '@/lib/data-transformer/gindumacUtils';
import { SuccessCheck } from './Success/SuccessCheck';
import { saveWantedlead } from '@/lib/repository/CRMRepository';
import { pushDataLayer } from '@/lib/utilities/analytics/gtm';

function WantedRequestModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation('products');

  type FormData = {
    'search-request_machine_category': string;
    'search-request_brand': string;
    'search-request_model': string;
    'search-request_year': string;
    'search-request_comments': string;
    'search-request_price_range': string;
    'search-request_city': string;
    'search-request_email': string;
    'search-request_company': string;
    'search-request_phone-prefix': string;
    'search-request_phone': string;
    'search-request_country': string;
    'search-request_first-name': string;
    'search-request_last-name': string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
  });
  const { tFuzz } = useTranslationFuz();

  async function submit(data: FormData) {
    console.log(data);
    try {
      await saveWantedlead({
        'first-name': data['search-request_first-name'],
        'last-name': data['search-request_last-name'],
        city: data['search-request_city'],
        email: data['search-request_email'],
        company: data['search-request_company'],
        country: data['search-request_country'],
        'phone-number': data['search-request_phone'],
        'phone-prefix': data['search-request_phone-prefix'],
        brand: data['search-request_brand'],
        comments: data['search-request_comments'],
        machine_category: data['search-request_machine_category'],
        model: data['search-request_model'],
        price_ranges: data['search-request_price_range'].replace('€', ''), // both those replaces should not be necessary but just in case
        year: data['search-request_year'],
        'platform-name': 'GINDUMAC Website',
        'platform-type': 'Wanted Request',
      });
      pushDataLayer('buttonClick', {
        label: 'Submit wanted request',
      });
    } catch (error) {
      console.error('errror', error);
    }
  }
  const { locale } = useRouter();
  const { data: budgetRangeOptions } = useQuery(['range'], () =>
    getWantedRequestPriceRangeOptions(locale ?? 'en-US')
  );
  return (
    <>
      <Drawer hasLogo isOpen={isOpen} setIsOpen={setIsOpen} size='full'>
        <hr />
        <div className='' data-testid='get-search-request_form'>
          {!isSubmitSuccessful ? (
            <>
              <h1 className='my-6 text-center'>
                {t('products:SEARCH_REQUEST_FORM_HEADER')}
              </h1>
              <form
                className='mx-auto max-w-xl'
                onSubmit={handleSubmit(submit)}
              >
                <FormTitle title={t('products:SEARCH_REQUEST_FORM_P1')} />

                <div className='mt-6 flex space-x-2'>
                  <div className='w-1/2'>
                    <Select<FormData>
                      label={t('products:FORM_SELECT_CATEGORY')}
                      options={getCategories().map((category) => ({
                        value: category.name,
                        label: tFuzz(category.name),
                      }))}
                      errors={errors}
                      register={register}
                      name='search-request_machine_category'
                      validation={{ required: true }}
                    />
                  </div>
                  <div className='w-1/2'>
                    <Input<FormData>
                      name='search-request_brand'
                      register={register}
                      errors={errors}
                      placeholder={t('FORM_PLACEHOLDER_BRANDS').toUpperCase()}
                      validation={{
                        required: true,
                        maxLength: 50,
                      }}
                    />
                  </div>
                </div>
                <div className='flex space-x-2'>
                  <div className='w-1/2'>
                    <Input<FormData>
                      name='search-request_model'
                      register={register}
                      errors={errors}
                      placeholder={t('FORM_PLACEHOLDER_MODELS').toUpperCase()}
                      validation={{
                        required: true,
                        maxLength: 50,
                      }}
                    />
                  </div>
                  <div className='w-1/2'>
                    <Input<FormData>
                      name='search-request_year'
                      register={register}
                      errors={errors}
                      placeholder={t('FORM_PLACEHOLDER_YOM').toUpperCase()}
                      validation={{
                        required: true,
                        maxLength: 50,
                      }}
                      type='number'
                    />
                  </div>
                </div>
                <Input<FormData>
                  name='search-request_comments'
                  register={register}
                  errors={errors}
                  placeholder={t(
                    'FORM_PLACEHOLDER_DETAILED_COMMENTS'
                  ).toUpperCase()}
                  validation={{
                    required: true,
                    maxLength: 120,
                  }}
                  type='textarea'
                />

                {/* Budget Select option  */}

                {budgetRangeOptions && (
                  <div className='mt-4'>
                    <Select<FormData>
                      label={t(
                        'products:SEARCH_REQUEST_BUDGET_RANGE'
                      ).toUpperCase()}
                      options={budgetRangeOptions?.['price-ranges'].map(
                        (v: [number, number?]) => ({
                          value:
                            v[0].toLocaleString('en-US') +
                            (v[1]
                              ? ' - ' + v[1].toLocaleString('en-US')
                              : ' and over.'),
                          label:
                            currencyFormat(v[0]) +
                            ' - ' +
                            (v[1]
                              ? currencyFormat(v[1])
                              : t('products:FORM_PRICE_RANGE_OVER')
                            ).toLowerCase(),
                        })
                      )}
                      errors={errors}
                      register={register}
                      name='search-request_price_range'
                      validation={{ required: true }}
                    />
                  </div>
                )}

                <div className='space-y-2'>
                  <h3 className='my-4 text-center'>
                    {t('products:DETAIL_MODALQUOTE_SUBHEADER3')}
                  </h3>
                  <div className='flex space-x-2'>
                    <div className='w-1/2'>
                      <Input
                        name='search-request_first-name'
                        register={register}
                        errors={errors}
                        placeholder={t('FORM_PLACEHOLDER_FIRSTNAME')}
                        validation={{
                          required: true,
                          maxLength: 50,
                        }}
                      />
                    </div>
                    <div className='w-1/2'>
                      <Input
                        name='search-request_last-name'
                        register={register}
                        errors={errors}
                        placeholder={t('FORM_PLACEHOLDER_LASTNAME')}
                        validation={{
                          required: true,
                          maxLength: 50,
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex space-x-2'>
                    <div className='w-1/2'>
                      <Input
                        name='search-request_company'
                        register={register}
                        errors={errors}
                        placeholder={t('FORM_PLACEHOLDER_COMPANY')}
                        validation={{
                          required: true,
                          maxLength: 100,
                        }}
                      />
                    </div>
                    <div className='w-1/2'>
                      <Input
                        type='email'
                        name='search-request_email'
                        register={register}
                        errors={errors}
                        placeholder={t('FORM_PLACEHOLDER_EMAIL')}
                        validation={{
                          required: true,
                          maxLength: 100,
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex space-x-2'>
                    <div className='w-1/2'>
                      <SelectCountry
                        type='phoneCode'
                        name='search-request_phone-prefix'
                        register={register}
                        errors={errors}
                        validation={{
                          required: true,
                        }}
                      />
                    </div>
                    <div className='w-1/2'>
                      <Input
                        type='number'
                        name='search-request_phone'
                        register={register}
                        errors={errors}
                        placeholder={t('FORM_PLACEHOLDER_PHONE')}
                        validation={{
                          required: true,
                          maxLength: 20,
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex space-x-2'>
                    <div className='w-1/2'>
                      <Input
                        name='search-request_city'
                        register={register}
                        errors={errors}
                        placeholder={t('FORM_PLACEHOLDER_CITY').toUpperCase()}
                        validation={{
                          required: true,
                          maxLength: 100,
                        }}
                      />
                    </div>
                    <div className='w-1/2'>
                      <SelectCountry
                        type='country'
                        name='search-request_country'
                        register={register}
                        errors={errors}
                        validation={{
                          required: true,
                        }}
                      />
                    </div>
                  </div>
                  <InputTerms
                    name='search-request_terms'
                    register={register}
                    errors={errors}
                  />
                </div>
                <button
                  type='submit'
                  className='mx-auto mb-12 mt-4 rounded bg-brand-500 px-8 py-4 text-center uppercase text-white hover:bg-brand-400'
                  data-testid='submit-wanted-request'
                  disabled={isSubmitting}
                >
                  {t('products:DETAIL_MODALQUOTE_BTN_SEND')}
                </button>
              </form>
            </>
          ) : (
            <SuccessCheck
              subtitle={t('products:SUCCESS_MESSAGE_SEARCH_REQUEST_HEADER2')}
            />
          )}
        </div>
      </Drawer>
    </>
  );
}

export function FormTitle({ title }: { title: string }) {
  return (
    <div>
      <p className='text-center text-muted-500'>{title}</p>
    </div>
  );
}

export default WantedRequestModal;
