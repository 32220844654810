import {
  ApplicationTypeFilter,
  BrandFilter,
  ControlUnitBrandFilter,
  Filters,
  LocalizationCountryFilter,
  MachineTypeFilter,
  ModelFilter,
} from '@/lib/types/api';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { isEmpty, orderBy, upperFirst } from 'lodash-es';
import useTranslation from 'next-translate/useTranslation';
import Autocomplete, { ValueLabelSlug } from '@/components/ui/Autocomplete';
import Disclosure from '@/components/ui/Disclosure';
import {
  setApplicationType,
  setBrands,
  setControlUnitBrand,
  setLocalizationContinent,
  setLocalizationCountry,
  setMachineType,
  setMaxPrice,
  setMinPrice,
  setModels,
  setPage,
  setYearRange,
} from './Filters/filterSlice';
import {
  currencyFormat,
  removeParenthesis,
  slugify,
} from '@/lib/data-transformer/gindumacUtils';
import { TechnicalFilters } from './Filters/TechnicalFilters';
import { useRouter } from 'next-translate-routes-multi-domain';
import classNames from 'classnames';
import useTranslate from '@/lib/hooks/useTranslate';
import { getCategories } from '@/lib/repository/machinesRepository';

export function getBrandOption(brand: BrandFilter) {
  return {
    label: brand.name && brand.name.toUpperCase(),
    value: brand.id,
    slug: brand.slug,
  };
}
export function useBrandOptions(brands?: BrandFilter[]) {
  return brands ? brands.map((brand) => getBrandOption(brand)) : [];
}

export function getModelOption(model: ModelFilter) {
  return {
    label: model.name && model.name.toUpperCase(),
    value: model.slug,
    slug: model.slug,
  };
}
export function useModelOptions(models?: ModelFilter[]) {
  return models ? models.map((model) => getModelOption(model)) : [];
}

export function useGetApplicationTypeOption() {
  const { t } = useTranslate();
  function getAppTypeOption(application_type: ApplicationTypeFilter) {
    let name = application_type.name
      ? t('products:' + application_type.name)
      : '';
    if (!name) {
      const map = {
        '528f0cbf-aa19-42dc-9c35-2014323e587d': '3DPrinting',
        '1d20e993-af80-4fa8-a2e4-e2312d7cfedb': 'Deburring',
        '21bda2c9-6469-46de-be71-de3703a55a9b': 'Bending',
        '4d0e86bb-f031-4d17-833f-7f163f8e81be': 'Blow Moulding',
        'a82f07f0-0b51-4853-81d6-724d8ebf22f7': 'Boring',
        '78d00ee5-980b-41c9-8ea3-15d3da8c8254': 'Compounding',
        '60cc13b9-418d-47ff-87e5-ec9492c24d82': 'Drilling',
        '85bae223-a5d8-4360-999d-908c752ca9fc': 'EDM',
        '9d331833-3c0d-4b91-9307-96530115e3e2': 'Extrusion',
        '97994b65-5226-46d5-a090-cae41cffb6c9': 'Gear Hobbing',
        '8ad93d3a-3313-4056-9c3d-99dccae7893c': 'Grinding',
        '2ed5a738-48f2-4cec-ac16-da6da05e6245': 'Injection Moulding',
        'ac6caffd-e3cc-424c-83e6-bfd37a248110': 'Laser Cutting',
        'c0946668-825a-4c92-acb1-3e78692d6fe0': 'Laser Marking',
        '66923dc0-aaf7-4f11-ae7e-27d8277cb401': 'Laser Welding',
        'da371dc0-030b-46ec-9523-ee0a96e006e9': 'Measuring',
        '62c734b2-692a-4c5c-aeec-27357e28a846': 'Milling',
        '69b6fb41-b380-4169-9c5a-adcd826477f8': 'Other',
        '5ab44f87-e3d2-4ed4-bcff-41954eeabb2a': 'Other (AE)',
        '6f8713f9-68c0-4a73-ac3b-a9e1fb95b87c': 'Other (IMM)',
        '235794b4-a017-421a-a566-d0b6eec0e2d5': 'Other (MT)',
        '60528568-8bd1-4e88-8b02-c31a61f1f329': 'Other (SM)',
        '7c885253-1dd1-4152-a3e5-cd7039d24c50': 'Plasma Cutting',
        'c49bbc44-63fa-43ec-ae47-f0a03d989d14': 'Punching',
        'da981823-5fb9-4219-aa11-65579b34b15a': 'Robotics',
        'f33ec68a-ef02-447f-9ef2-703c948aa895': 'Sawing',
        '10846585-24a7-46d1-ada1-dd6074c03d93': 'Shaping',
        'ce9bc4b5-a63f-43e8-8ec6-67644f24cb8a': 'Shearing',
        'c9cb7782-2ef7-49e5-80c2-b6b5752e8456': 'SMT',
        'e6b6008a-a591-4f20-b37a-0dbce60b86a8': 'Stamping/Pressing',
        '1f16dade-c9af-488c-ab12-a40565384dfb': 'Turning',
        '2649b651-f8ef-453f-b4ad-049bc9fd72e9': 'Water Cutting',
        '5d7d9b97-ab84-4cb0-ad1f-ae82c636ac79': 'Welding',
      };
      if (application_type.key in map) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // this is a hack to get around the fact that the API doesn't return a name for some application types
        name = map[application_type.key];
      } else {
        console.warn(
          'useGetApplicationTypeOption() error: no API name for application :',
          application_type.key
        );
      }
      // Application Type ID to name for when the API doesn't return a name
    }
    if (!name) {
      console.log(
        'slufiy will complain, error: no name for application :',
        application_type.key
      );
    }
    return {
      label: name.toUpperCase(),
      value: application_type.id,
      slug: slugify(name),
    };
  }
  return { getAppTypeOption };
}

function useApplicationTypeOptions(applicationTypes?: ApplicationTypeFilter[]) {
  const { getAppTypeOption } = useGetApplicationTypeOption();
  return applicationTypes
    ? applicationTypes.map((application_type) =>
        getAppTypeOption(application_type)
      )
    : [];
}

export function useGetMachineTypeOption() {
  const { t } = useTranslate();
  function getMachineTypeOption(machine_type: MachineTypeFilter) {
    let name = t('products:' + machine_type.name);
    if (!name) {
      const localNameBackup = [
        {
          key: 'cafc248e-0bdb-45a9-8a1e-2bb27399d375',
          name: '3D Printer',
        },
        {
          key: 'ad6669ef-d868-40bb-9a51-4fd15831ab2e',
          name: 'Bending machines',
        },
        {
          key: '86b05544-ba40-4e9f-9684-fddad81d91b9',
          name: 'Blow Moulding Machines',
        },
        {
          key: '20a269e9-b63d-486d-85d7-7f20b4bb7e20',
          name: 'Boring machines',
        },
        {
          key: 'a9ae3532-8197-4bd4-826f-9bae7cafc527',
          name: 'Compounding Extrusion Line',
        },
        {
          key: '3a28e2d7-c6c2-43ed-90dc-518dd46e5751',
          name: 'Compressor',
        },
        {
          key: '9391096d-9603-4280-a8b1-ad5044e3ca98',
          name: 'Copy Router',
        },
        {
          key: 'ce743c82-da99-4060-8336-7b441a9e18f8',
          name: 'Drilling machines',
        },
        {
          key: '6f406a33-e918-4a85-b6f3-9bad3330ae2d',
          name: 'Erosion machines',
        },
        {
          key: '5a171539-add9-4d34-a2b7-23121997a768',
          name: 'Extrusion',
        },
        {
          key: 'f35185b0-7440-45b6-a68e-f2f0feb68adc',
          name: 'Fiber laser cutting machines',
        },
        {
          key: 'f5a8f91a-ded6-4065-9270-3477aa0b3d7e',
          name: 'Grinding machines',
        },
        {
          key: '8d332f1c-4f4f-4fa5-a05e-149edb7ff0f9',
          name: 'Injection Moulding Compounder',
        },
        {
          key: '70e9fbd1-6e15-442d-9c31-8637efc6f4b8',
          name: 'Injection moulding machines',
        },
        {
          key: '250cd61e-2e5b-4380-8075-d42fb4b1034b',
          name: 'Laser cutting machines',
        },
        {
          key: '11a03070-a025-458b-ab53-7d6e19e99735',
          name: 'Laser engraving and cutting machine',
        },
        {
          key: '0e38feb1-7faf-477e-a785-124bf9a82302',
          name: 'Laser punch combination machine',
        },
        {
          key: '9bc31e14-3127-45cf-854e-692928060e84',
          name: 'Laser welding machines',
        },
        {
          key: '56ac56ae-4ca5-4be4-8bac-c3479a06ddfc',
          name: 'Lathes',
        },
        {
          key: '4d31bc98-0b00-4d7c-8a40-1e4ac9f0d2d3',
          name: 'Machining centers (horizontal)',
        },
        {
          key: '30ce6dc1-0851-4e9d-8dc5-b4e0169b8a5a',
          name: 'Machining centers (universal)',
        },
        {
          key: '32dcdb32-1fdd-47c5-915a-e25135a9d456',
          name: 'Machining centers (vertical)',
        },
        {
          key: '9df1b414-4e54-45f3-b7a7-13601fe9c332',
          name: 'Measuring machines',
        },
        {
          key: '9c228361-338d-4e38-afcb-5492e0a08924',
          name: 'Milling machines',
        },
        {
          key: '2c9aab93-9860-44be-a7c8-73298e6f8aec',
          name: 'Other',
        },
        {
          key: 'a8ad3193-84c3-43f4-b276-279f6c713feb',
          name: 'Pipe Bending',
        },
        {
          key: '6474c3c1-5970-4003-8461-003d80fb7da9',
          name: 'Plasma Cutting Machine',
        },
        {
          key: '79adcb7c-b0e1-487d-8051-113d8778596a',
          name: 'Press Brake',
        },
        {
          key: 'acca8470-e11c-4bfe-880f-631cd69509e3',
          name: 'Punching machines',
        },
        {
          key: 'fa6cd01c-3a76-4ac5-a322-6b9a7a9085f2',
          name: 'Robot',
        },
        {
          key: 'cf9b6ed6-c66e-4215-b2b7-0bb8176c48db',
          name: 'Rubber Injection Presses',
        },
        {
          key: '5765efc2-ab59-4f2e-ab21-2049ea6a50a9',
          name: 'Sawing machines',
        },
        {
          key: '62423eb2-82a5-4b90-9e6b-7a4244520033',
          name: 'Section Bending Machine',
        },
        {
          key: '275b0dac-3c5c-4246-b459-5c54caadd56b',
          name: 'Shaping machines',
        },
        {
          key: 'fc4cf5c2-b7ba-46b3-85c3-bf7d7a8958ab',
          name: 'Shearing machines',
        },
        {
          key: 'f2ff97e0-f488-4aab-aa76-28842b7315b8',
          name: 'SMT',
        },
        {
          key: '6f4de062-8e42-4080-8f98-c3e13f7cc694',
          name: 'Spare parts',
        },
        {
          key: 'd092daab-37b4-4ce9-8be6-7e679bba0011',
          name: 'Stamping machines',
        },
        {
          key: '4d2cd3ff-0ec6-4abb-8db8-94ad6ae003d2',
          name: 'Turret punching machines',
        },
        {
          key: '059fd3c4-e359-4fcb-ac1a-16109d285211',
          name: 'Waterjet cutting machines',
        },
        {
          key: 'f631bf6c-188c-473f-ac59-0275eb0001c4',
          name: 'Welding',
        },
      ];
      const machineOptionName = localNameBackup.find(
        (item) => item.key === machine_type.key
      );
      if (machineOptionName) {
        name = machineOptionName.name;
      }
    }
    return {
      label: name.toUpperCase(),
      value: machine_type.id,
      slug: removeParenthesis(slugify(name)),
    };
  }
  return { getMachineTypeOption };
}

export function useMachineTypeOptions(machineTypes?: MachineTypeFilter[]) {
  const { getMachineTypeOption } = useGetMachineTypeOption();

  return machineTypes
    ? machineTypes.map((machineType) => getMachineTypeOption(machineType))
    : [];
}

export function getLocalizationCountryOption(
  country: LocalizationCountryFilter
) {
  return {
    label: country?.name && country.name.toUpperCase(),
    value: country.id,
    slug: country?.country_code && country.country_code.toUpperCase(),
  };
}

export function useLocationOptions(countries?: LocalizationCountryFilter[]) {
  const { t } = useTranslate();
  const options = countries
    ? countries.map((country) => ({
        label: t('products:' + country?.name).toUpperCase(),
        value: country.id,
        slug: country?.country_code && country.country_code.toUpperCase(),
      }))
    : [];
  return orderBy(options, 'label');
}

export function getControlUnitOption(control_unit: ControlUnitBrandFilter) {
  return {
    label: control_unit?.name && control_unit.name.toUpperCase(),
    value: control_unit.slug,
    slug: control_unit.slug,
  };
}

export function useControlUnitOptions(controlUnits?: ControlUnitBrandFilter[]) {
  return controlUnits
    ? controlUnits.map((control_unit) => getControlUnitOption(control_unit))
    : [];
}

export default function ProductFilters({ filters }: { filters?: Filters }) {
  const { t } = useTranslation('products');
  const productFilter = useAppSelector((state) => state.productFilter);
  const dispatch = useAppDispatch();
  const brandOptions = useBrandOptions(filters?.brands);
  const modelOptions = useModelOptions(filters?.models);
  // we disable the models options because the API returns all models when inside a category ( even models for other categories, so we disable before the user selects a brand and then the API will return valid models to select)
  const disableModelOptions = isEmpty(productFilter?.brand);
  const applicationTypeOptions = useApplicationTypeOptions(
    filters?.application_types
  );
  const machineTypeOptions = useMachineTypeOptions(filters?.machine_types);
  const locationOptions = useLocationOptions(filters?.localization_countries);
  const controlUnitOptions = useControlUnitOptions(
    filters?.control_unit_brands
  );

  function parseNumberOrUndefined(string: string) {
    const parsed = parseInt(string);
    return isNaN(parsed) ? undefined : parsed;
  }

  const { locale } = useRouter();

  return (
    <>
      <div className='max-w-sm'>
        <Disclosure
          title={t('products:PRODUCT_LIST_FILTERS_MACHINECATEGORY')}
          isOpenDefaultState={true}
          links={getCategories().map((category) => ({
            label: t('products:' + category.name),
            href: category.href,
          }))}
        />
        <Disclosure title={t('products:Brand')}>
          <Autocomplete
            name='brand-autocomplete'
            placeholder={upperFirst(
              t('products:PRODUCT_LIST_FILTERS_SELECT_BRAND')
            )}
            isMulti={true}
            options={brandOptions}
            onChange={(value) => {
              dispatch(setPage(1));
              dispatch(setBrands(value as ValueLabelSlug[]));
            }}
            value={productFilter.brand}
          />
        </Disclosure>
        <Disclosure title={t('products:Model')}>
          <Autocomplete
            placeholder={upperFirst(
              t(
                'products:PRODUCT_LIST_FILTERS_SELECT_MODELS',
                {},
                {
                  fallback: 'Select model',
                }
              ).toUpperCase()
            )}
            name='model-autocomplete'
            isMulti={true}
            options={disableModelOptions ? [] : modelOptions}
            customNoOptionsMessage={
              disableModelOptions
                ? t('products:SELECT_A_BRAND_FIRST')
                : undefined
            }
            onChange={(value) => {
              dispatch(setPage(1));
              dispatch(setModels(value));
            }}
            value={productFilter.model}
          />
        </Disclosure>
        <TechnicalFilters />
        <Disclosure title={t('products:Application Type')}>
          <Autocomplete
            placeholder={upperFirst(
              t(
                'products:PRODUCT_LIST_FILTERS_SELECT_TYPE',
                {},
                {
                  fallback: 'Select type',
                }
              ).toUpperCase()
            )}
            name='application-type-autocomplete'
            isMulti={true}
            options={applicationTypeOptions}
            onChange={(value) => {
              dispatch(setPage(1));
              dispatch(setApplicationType(value));
            }}
            value={productFilter.application_type}
          />
        </Disclosure>
        <Disclosure title={t('products:Machine Type')}>
          <Autocomplete
            placeholder={upperFirst(
              t(
                'products:PRODUCT_LIST_FILTERS_SELECT_TYPE',
                {},
                {
                  fallback: 'Select type',
                }
              ).toUpperCase()
            )}
            name='machine-type-autocomplete'
            isMulti={true}
            options={machineTypeOptions}
            onChange={(value) => {
              dispatch(setPage(1));
              dispatch(setMachineType(value as ValueLabelSlug[]));
            }}
            value={productFilter.machine_type}
          />
        </Disclosure>
        <Disclosure title={t('products:Country')}>
          <Autocomplete
            placeholder={upperFirst(
              t('products:PRODUCT_LIST_FILTERS_SELECT_LOCATION')
            )}
            isMulti={true}
            options={locationOptions}
            onChange={(value) => {
              dispatch(setPage(1));
              dispatch(setLocalizationCountry(value));
            }}
            value={productFilter.localization_country}
            name='location-autocomplete'
          />
          <button
            className={classNames(
              'btn mt-2 w-full uppercase',
              !isEmpty(productFilter.localization_continent)
                ? 'bg-brand-500 text-white'
                : 'border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white'
            )}
            onClick={() => {
              dispatch(setPage(1));
              dispatch(
                setLocalizationContinent(
                  productFilter.localization_continent ? undefined : 'EU'
                )
              );
            }}
          >
            {t('products:LOCATION_CONTINENT_EU')}
          </button>
        </Disclosure>
        <Disclosure
          title={t('products:PRODUCT_LIST_FILTERS_TITLE_YEAR')}
          options={[
            {
              label: t('products:PRODUCT_LIST_FILTERS_YEARS_LESSTHAN5'),
              value: '5',
            },
            {
              label: t('products:PRODUCT_LIST_FILTERS_YEARS_LESSTHAN10'),
              value: '10',
            },
            {
              label: t('products:PRODUCT_LIST_FILTERS_YEARS_LESSTHAN15'),
              value: '15',
            },
            {
              label: t('products:PRODUCT_LIST_FILTERS_YEARS_LESSTHAN20'),
              value: '20',
            },
            {
              label: t('products:PRODUCT_LIST_FILTERS_YEARS_LESSTHAN25'),
              value: '25',
            },
            {
              label: t('products:PRODUCT_LIST_FILTERS_YEARS_LESSTHAN30'),
              value: '30',
            },
          ]}
          onOptionChange={(option) => {
            dispatch(setPage(1));
            dispatch(
              setYearRange(
                productFilter.year_range == option.value
                  ? undefined
                  : option.value
              )
            );
          }}
          isOptionActive={(option) => option.value === productFilter.year_range}
          dataTestId='filter-year'
        >
          {/* TODO: years filter from and too */}
          {/* <div className='mt-2 flex items-center justify-between space-x-2'>
            <label className='text-brand-500'>From:</label>
            <input type='number' name='from' id='min-year' placeholder='1990' />
          </div>
          <div className='mt-2 flex items-center justify-between space-x-2'>
            <label className='text-brand-500'>To:</label>
            <input type='number' name='to' id='max-year' placeholder='2022' />
          </div> */}
        </Disclosure>
        <Disclosure title={t('products:Control Unit')}>
          <Autocomplete
            placeholder={t('products:PRODUCT_LIST_FILTERS_SELECT_CONTROL_UNIT')}
            isMulti={true}
            options={controlUnitOptions}
            onChange={(value) => {
              dispatch(setPage(1));
              dispatch(setControlUnitBrand(value.map((v) => v.value)));
            }}
            value={controlUnitOptions.filter((option) =>
              productFilter.control_unit_brand?.includes(option.value)
            )}
            name='control-unit-autocomplete'
          />
        </Disclosure>
        <Disclosure title={t('products:PRODUCT_LIST_FILTERS_TITLE_PRICERANGE')}>
          <div className='flex items-center justify-between space-x-2'>
            <label className='text-brand-500'>
              {t('products:PRODUCT_LIST_FILTERS_FROM')}:
            </label>
            <input
              type='number'
              name='from'
              id='min-price'
              placeholder='0'
              onChange={(e) => {
                dispatch(setPage(1));
                dispatch(setMinPrice(parseNumberOrUndefined(e.target.value)));
              }}
              value={productFilter.min_price ?? ''}
            />
          </div>
          <div className='mt-2 flex items-center justify-between space-x-2'>
            <label className='text-brand-500'>
              {t('products:PRODUCT_LIST_FILTERS_TO')}:
            </label>
            <input
              type='number'
              name='to'
              id='max-price'
              placeholder={
                filters?.prices?.max
                  ? currencyFormat(filters?.prices?.max, locale).toString()
                  : ''
              }
              onChange={(e) => {
                dispatch(setPage(1));
                dispatch(setMaxPrice(parseNumberOrUndefined(e.target.value)));
              }}
              value={productFilter.max_price ?? ''}
            />
          </div>
        </Disclosure>
      </div>
    </>
  );
}
