import axios from 'axios';

const API_URL = process.env.NEXT_PUBLIC_CRM_API_URL;

interface leadI {
  city: string;
  company: string;
  country: string;
  email: string;
  'first-name': string;
  'last-name': string;
  'phone-number': string;
  'phone-prefix': string;
  'platform-name': string;
  'platform-type': string;
}

interface buylead extends leadI {
  sku: string;
  subject?: string;
  inspection?: boolean;
  finance?: boolean;
  transport?: boolean;
  'on-site-installment'?: boolean;
  'transport-address'?: string;
  offer?: string;
  currency?: string;
  message?: string;
}
interface selllead extends leadI {
  brand: string;
  model: string;
  year: string;
  machine_category: string;
  'machine-under-power': '1' | '0'; // for some reason, the API expects a string
  'has-files'?: boolean; // this will only be send on the third step
  original_equipment_value?: string;
  production_hours?: string;
  comments?: string;
}

interface wantedlead extends leadI {
  brand: string;
  model: string;
  year: string;
  machine_category: string;
  price_ranges: string;
  comments: string;
}

interface liquidationprojectlead extends leadI {
  message: string;
}

export function saveBuylead(lead: buylead) {
  if (!lead['transport']) {
    if (!lead['on-site-installment']) {
      delete lead['transport-address'];
    }
  }
  return axios.post(`${API_URL}buylead`, lead);
}

export function liquidationprojectlead(lead: liquidationprojectlead) {
  return axios.post(`${API_URL}liquidationprojectlead`, lead);
}

export function saveSelllead(lead: selllead) {
  return axios.post(`${API_URL}selllead`, lead, {
    headers: {
      Authorization: `Token ${process.env.NEXT_PUBLIC_CRM_API_TOKEN}`,
    },
  });
}
export function updateSelllead(lead: Partial<selllead>, id: string) {
  return axios.put(`${API_URL}selllead/${id}`, lead, {
    headers: {
      Authorization: `Token ${process.env.NEXT_PUBLIC_CRM_API_TOKEN}`,
    },
  });
}

export function saveWantedlead(lead: wantedlead) {
  return axios.post(`${API_URL}wantedlead`, {
    ...lead,
  });
}
