import { FormDataStep1 } from '@/pages/selling-process';
import { FormDataStep2 } from '@/pages/selling-process/step-2';
import { FormDataStep3 } from '@/pages/selling-process/step-3';
import { isNil, omitBy } from 'lodash-es';
import { saveSelllead, updateSelllead } from './CRMRepository';
import axios from 'axios';
import { getApiFromLocal } from '../data-transformer/getApiDomainFromLocale';

export function submitSell(data: FormDataStep2 & FormDataStep1) {
  return saveSelllead({
    brand: data['machine-details_brand'],
    model: data['machine-details_model'],
    year: data['machine-details_year'].toString(),
    machine_category: data['machine-details_machine_category'],
    'machine-under-power': data['machine-details_under_power'] ? '1' : '0',
    'first-name': data['customer-details_firstname'],
    'last-name': data['customer-details_lastname'],
    email: data['customer-details_email'],
    'phone-number': data['customer-details_phone-number'],
    'phone-prefix': data['customer-details_phone-prefix'],
    country: data['customer-details_country'],
    city: data['customer-details_city'],
    company: data['subscribe-company'],
    'platform-name': 'GINDUMAC Website',
    'platform-type': 'Sell Request',
  });
}

export function updateSell(
  id: string,
  data: Partial<FormDataStep2 & FormDataStep1 & FormDataStep3>
) {
  // remove null and undefined
  const dataWithoutUndefineds = omitBy(
    {
      brand: data['machine-details_brand'],
      model: data['machine-details_model'],
      year: data['machine-details_year'],
      machine_category: data['machine-details_machine_category'],
      'machine-under-power': data['machine-details_under_power'],
      'has-files': data['has-files'],
      comments: data.comments,
    },
    isNil
  );
  return updateSelllead(dataWithoutUndefineds, id);
}

export function getWantedRequestPriceRangeOptions(locale: string) {
  console.log('fetching budget range');
  return axios
    .get(getApiFromLocal(locale) + '/api/price/ranges')
    .then(function (res) {
      return res?.data?.data?.[0];
    })
    .catch(() => []);
}
