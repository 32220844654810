import {
  ApiProductsResponse,
  INameAndSlug,
  ModelFilter,
} from '@/lib/types/api';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { debounce } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import {
  setApplicationType,
  setBrands,
  setModels,
  setPage,
  setSearch,
} from './filterSlice';
import { Filters } from '@/lib/types/api';
import { fetchSuggestions } from '@/lib/repository/machinesRepository';
import CreatableSelect from 'react-select/creatable';
import {
  AutocompleteProps,
  GroupedOptions,
} from '@/components/ui/Autocomplete';
import useTranslation from 'next-translate/useTranslation';
import { slugify } from '@/lib/data-transformer/gindumacUtils';
import { useRouter } from 'next-translate-routes-multi-domain';
import { FaSearch } from 'react-icons/fa';
import { DropdownIndicatorProps, components } from 'react-select';
import useIsMobile from '@/lib/hooks/useIsMobile';

type SearchbarKeys = 'brands' | 'models' | 'application_types' | 'search';
export function AutocompleteSearchBar({
  productsResponse,
}: {
  productsResponse: ApiProductsResponse;
}) {
  const [searchValue, setSearchValue] = useState('');
  const { brand, application_type, model, search } = useAppSelector(
    (state) => state.productFilter
  );
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<GroupedOptions[]>([]);
  const { locale } = useRouter();
  const { t } = useTranslation('products');

  useEffect(() => {
    if (searchValue && locale) {
      fetchSuggestions(searchValue, locale)
        .then((res) => {
          const options = fixApiResponse(
            res.data as IResponseData,
            productsResponse.data.filters
          );
          setOptions(options);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [productsResponse.data.filters, searchValue, locale]);

  // Update the state at max every 300ms ( one req every 300ms max )
  const debouncedSearch = debounce((criteria) => {
    setSearchValue(criteria);
  }, 300);

  // We use debounce to avoid making a request on every keystroke
  function debouncedSetSearchValue(value: string) {
    debouncedSearch(value);
    return value;
  }

  function handleOnChange(
    values: {
      value: string;
      label: string;
      key?: SearchbarKeys;
      slug: string;
    }[]
  ) {
    // the API returned only name and slug for each option without id but we need to store the id to send it :(
    // first separate the selections
    const brands = values.filter((v) => v?.key === ('brands' as SearchbarKeys));
    const models = values.filter((v) => v?.key === ('models' as SearchbarKeys));
    const application_types = values.filter(
      (v) => v?.key === ('application_types' as SearchbarKeys)
    );
    const searchText = values.find(
      (v) => v?.key === ('search' as SearchbarKeys)
    );
    dispatch(setBrands(brands));
    dispatch(setModels(models));
    dispatch(setApplicationType(application_types));
    dispatch(setSearch(searchText?.value));
    dispatch(setPage(1));
  }

  // we need to pass the selected values as an array of objects with value and label but we need to add a key to each object to know handle onChange
  const parsedSelections: {
    label: string;
    value: string;
    key: SearchbarKeys;
  }[] = [
    ...(brand ?? []).map((b) => ({ ...b, key: 'brands' as SearchbarKeys })),
    ...(model ?? []).map((b) => ({ ...b, key: 'models' as SearchbarKeys })),
    ...(application_type ?? []).map((b) => ({
      ...b,
      key: 'application_types' as SearchbarKeys,
    })),
  ];
  if (search) {
    parsedSelections.push({
      label: search,
      value: search,
      key: 'search' as SearchbarKeys,
    });
  }
  const isMobile = useIsMobile();

  return (
    <CreatableAutocomplete
      name='product-filter-searchbar'
      isMulti
      options={options}
      onInputChange={debouncedSetSearchValue}
      onChange={handleOnChange as any}
      disableNoOptionsMessage
      value={parsedSelections}
      onCreateOption={(value) => {
        dispatch(setSearch(value));
      }}
      placeholder={
        isMobile
          ? t('products:SEARCH').toUpperCase()
          : t('products:PRODUCT_LIST_SEARCH_PLACEHOLDER')
      }
      isSearchbar
    />
  );
}
export default function CreatableAutocomplete(
  props: AutocompleteProps & {
    disableNoOptionsMessage?: boolean;
    onCreateOption?: (value: string) => void;
    name?: string;
    isSearchbar?: boolean;
  }
) {
  const customStyles = {
    input: (provided: any) => ({
      ...provided,
      'input:focus': {
        boxShadow: 'none',
        borderRadiu: '2em',
      },
    }),
    control: (provider: any) => ({
      ...provider,
      height: '3rem',
      borderRadiu: props.borderRadius ?? '0px',
      'control:focus': {
        borderColor: '#6b7280',
      },
    }),
  };
  const { t } = useTranslation();
  return (
    <div className={props.containerClasses}>
      <label className='input-label' htmlFor={props.name}>
        {props.label}
      </label>
      <CreatableSelect
        name={props.name}
        placeholder={props.placeholder}
        options={props.options}
        styles={customStyles}
        onChange={props.onChange as any}
        isMulti={props.isMulti}
        id={props.name}
        onInputChange={props.onInputChange as any}
        value={props.value}
        noOptionsMessage={
          () => (props.disableNoOptionsMessage ? '' : 'No options') // TODO: i18n
        }
        onCreateOption={props.onCreateOption}
        formatCreateLabel={(value) => t('products:SEARCH') + `: ${value}`}
        createOptionPosition='first'
        components={props.isSearchbar ? { DropdownIndicator } : undefined}
      />
    </div>
  );
}

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSearch />
    </components.DropdownIndicator>
  );
};

// The api returns a list of options with slug and name only but for application type and brand we should use the id ( not the slug ) to filter
// so we need to find the id of the selected option in the filters list
// TODO: API v2, refactor this
interface IResponseData {
  brands: INameAndSlug[];
  models: ModelFilter[];
  application_types: INameAndSlug[];
}
function fixApiResponse(response: IResponseData, filters: Filters) {
  const options = Object.keys(response).map((key) => ({
    label: key,
    options: response[key as keyof IResponseData]
      .map((o: { slug: string; name: string }) => {
        switch (key) {
          case 'brands':
            // eslint-disable-next-line no-case-declarations
            const brand = filters.brands.find((br) => br.slug === o.slug);
            if (!brand) {
              console.warn('No value found for selected option: ', o);
              return;
            }
            return {
              value: brand.id,
              label: o.name,
              key: key,
              slug: brand.slug,
            };
          case 'models':
            return {
              value: o.slug,
              label: o.name,
              key: key,
              slug: o.slug,
            };
          case 'application_types':
            // eslint-disable-next-line no-case-declarations
            const appType = filters.application_types.find(
              (br) => br.slug === o.slug
            );
            if (!appType?.id) {
              console.warn('No value found for selected option: ', o);
              return;
            }
            return {
              value: appType.id,
              label: o.name,
              key: key,
              slug: slugify(o.name),
            };
          default:
            console.error('No value found for selected option: ', o);
            return {
              value: o.slug,
              label: o.name,
              key: key,
              slug: o.slug,
            };
        }
      })
      .filter((o) => o !== undefined) as {
      value: string;
      label: string;
      key: string;
      slug: string;
    }[],
  }));
  return options;
}
